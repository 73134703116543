import Vue from "@/helper/initVue";
import MeterReadingForm from "@/components/forms/meter-reading/MeterReadingForm.vue";

const meterReadingFormElements = document.querySelectorAll(
  ".v-meter-reading-form"
);

Array.from(meterReadingFormElements).forEach((meterReadingFormElement) => {
  if (meterReadingFormElement) {
    const form = meterReadingFormElement.getAttribute("data-form") ?? "";
    const success = meterReadingFormElement.getAttribute("data-success") ?? "";
    const error = meterReadingFormElement.getAttribute("data-error") ?? "";

    new Vue({
      el: meterReadingFormElement,
      render: (h) =>
        h(MeterReadingForm, {
          props: {
            form: form,
            links: {
              success: success,
              error: error,
            },
          },
        }),
    });
  }
});
