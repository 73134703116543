


















































































































































































































































import axios from "axios";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import LoadingButton from "@/shared/components/LoadingButton";

import MeterReadingError from "./MeterReadingErrorFallback.vue";

export default defineComponent({
  name: "MeterReadingForm",
  components: {
    ValidationObserver,
    MeterReadingError,
    LoadingButton,
  },
  props: {
    form: {
      type: String,
      default: "",
    },
    links: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const observerRef = ref<any>();
    let isSubmitting = ref(false);

    const errors = ref([]);

    const showGas = ref(false);
    const showHeating = ref(false);
    const showElectricity = ref(false);

    const showFallbackSuccess = ref(false);
    const showFallbackError = ref(false);

    const formPostURL = "/api/meterreadings?code=create";

    const formData = ref({
      isElectricity: null,
      isGas: null,
      isHeating: null,
      form: props.form || "",
      customerNumber: null,
      date: null,
      name: null,
      lastName: null,
      city: null,
      zip: null,
      street: null,
      streetNumber: null,
      email: null,
      phone: null,
      readings: {
        meternumberElectricityHt: null,
        meterresultElectricityHt: null,
        meternumberElectricityNt: null,
        meterresultElectricityNt: null,
        meternumberGas: null,
        meterresultGas: null,
        meternumberHeating: null,
        meterresultHeatingPower: null,
        meterresultHeatingVolume: null,
      },
    });

    const hasAtLeastOneReadingsValue = computed(() =>
      Object.values(formData.value.readings).some(
        (value) => value !== null && value !== ""
      )
    );

    async function handleFormSubmit() {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      isSubmitting.value = true;

      await axios
        .post(formPostURL, formData.value)
        .then((response) => {
          if (
            props.links.success &&
            response.status === 200 &&
            response.data.data.code === 200
          ) {
            window.location.href = props.links.success;
          } else {
            showFallbackSuccess.value = true;
          }
        })
        .catch((error) => {
          errors.value = error;

          if (props.links.error) {
            window.location.href = props.links.error;
          } else {
            showFallbackError.value = true;
          }
        });

      isSubmitting.value = false;
      observerRef.value!.reset();
    }

    return {
      formData,
      observerRef,
      showGas,
      showHeating,
      showElectricity,
      showFallbackSuccess,
      showFallbackError,
      isSubmitting,
      handleFormSubmit,
      hasAtLeastOneReadingsValue,
    };
  },
});
